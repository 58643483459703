import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 8-8-8\\@75% 1RM`}</p>
    <p>{`Hamstring Curls 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`”`}<strong parentName="p">{`The Chief`}</strong>{`”`}</p>
    <p>{`Max rounds in 3 minutes of:\\
3-Power cleans (135/95)\\
6-Push-ups\\
9-Squats`}</p>
    <p>{`Rest 1 minute.`}</p>
    <p>{`Repeat for a total of 5 cycles.`}</p>
    <p>{`Post rounds completed for each of the 5 cycles.`}</p>
    <p>{`Count only complete rounds, restarting at 3 Power Cleans each cycle.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      